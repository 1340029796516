<template>
  <div class="login-wrapper">
    <form @submit.prevent="submit()" novalidate class="login__form">
      <div class="form-group login__group" :class="{'has-error' : validation.hasError('user.email')}">
        <label for="email" class="control-label login__label" >Email</label>
        <input
                name="email"
                type="email"
                class="form-control"
                id="email"
                placeholder="Email"
                data-rules="required|email"
                v-model="user.email">
        <div v-show="validation.hasError('user.email')" class="help-block login__help">{{ validation.firstError('user.email') }}</div>
      </div>

      <div class="form-group" :class="{'has-error' : validation.hasError('user.password')}">
        <label for="password" class="control-label login__label">Пароль</label>
        <input
                name="password"
                type="password"
                class="form-control"
                id="password"
                placeholder="Пароль"
                v-model="user.password">
        <div v-show="validation.hasError('user.password')" class="help-block login__help">{{ validation.firstError('user.password') }}</div>
      </div>
      <button type="submit" class="btn login__btn">Войти</button>
    </form>
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import UserService from '@/services/user-service';
import { InitializeService } from '@/services/initialize-service';

export default {
  name: 'login',
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    submit() {
      this.$validate().then(success => {
        if (success) {
          UserService.instance.auth({
            email: this.user.email,
            password: this.user.password,
          })
            .then(() => {
              InitializeService.instance
                .initialize()
                .then(() => {
                  if (this.$store.getters['Auth/role'] === 3) {
                    this.$router.push({ name: 'city-car-change-main' });
                  } else if (this.user.email === 'vavilin@mail.ru') {
                    this.$router.push({ name: 'reports-transport-per-hour' });
                  } else {
                    this.$router.push({ name: 'main-map' });
                  }
                });
            })
            .catch(() => {});
        }
      });
    },
  },
  validators: {
    'user.email': val => Validator.value(val).required('Поле обязательно для заполнения').email('Поле должно быть корректным адресом электронной почты'),
    'user.password': val => Validator.value(val).required('Поле обязательно для заполнения').minLength(6, 'Минимальная длина пароля - 6 символов'),
  },
};
</script>

<style lang="scss" scoped>

.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/assets/images/arms_ulyanosvsk_s.png) center 0 no-repeat;

  form {
    background: #FFF;
  }
}

.login {

  &__form {
    max-width: 400px;
    width: 100%;
    box-shadow: var(--base-shadow);
    padding: 40px;

    input {

      &::placeholder {
        color: #D4D4D4;
      }
    }
  }

  &__group {
    margin-bottom: 15px;
  }

  &__label {
    font-family: 'GotemMedium', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #2B2E37;
  }

  &__btn {
    width: 100%;
    background: #00AD89;
    color: #fff;
    margin-top: 15px;
    height: 50px;
    font-size: 18px;

    &:hover {
      background: #009B7B;
      color: #fff;
    }
  }

  &__help {
    margin-top: 10px;
    color: #a4a4a4;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
